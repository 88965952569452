<template lang="pug">
v-dialog(:value="true" max-width="500px" @input="$emit('close')")
  v-card
    v-card-title.d-flex.justify-space-between
      span Удалить аккаунт
    v-card-text
      div Вы точно хотите удалить этого пользователя?
      v-row(align-end)
        v-col(cols="12" sm="6")
          v-btn(
            @click="$emit('close')"
            outlined
            block
          ) Отмена
        v-col(cols="12" sm="6")
          v-btn(
            @click="deleteAccount"
            block
            depressed
            color="primary"
            :loading="isLoading"
          ) Подтвердить
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    async deleteAccount() {
      this.isLoading = true;
      try {
        this.$store.dispatch('ACCOUNTS/delete', this.id);
        this.$notify({
          group: 'note',
          type: 'info',
          title: `Пользователь успешно удален`,
        });
        this.$emit('close');
      } catch (error) {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Ошибка удаления пользователя ' + this.email,
          text: error,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
